import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Block, Content, Flex, FlexSpacer, Input, Yoga } from 'gerami'

import { SchedulesPageQuery } from '../../../../graphql-types'
import { useSearch } from '../../../shared/contexts/search-index-context/search-index-context'
import useSearchMatch from '../../../shared/hooks/use-search-match/use-search-match'
import SEO from '../../../shared/components/seo/seo'
import Layout from '../../../shared/components/layout/layout'
import ScheduleCard from '../../components/schedule-card/schedule-card'
import EmailRegistration from '../../../shared/components/email-registration/email-registration'

type SchedulesProps = {}

const Schedules: React.FC<SchedulesProps> = ({}) => {
  const data = useStaticQuery<SchedulesPageQuery>(query)

  const [term, setTerm] = useState(``)
  const results = (useSearch(term, { expand: true }) || []).filter(
    result => result.template === 'schedule'
  )

  const featuredSchedules = useSearchMatch(
    data.featuredSchedules.edges.map(edge => edge.node),
    term.length ? results : undefined
  )
  const otherSchedules = useSearchMatch(
    data.otherSchedules.edges.map(edge => edge.node),
    term.length ? results : undefined
  )

  return (
    <>
      <SEO title="Schedules" />

      <Layout>
        <Content transparent size="4XL">
          <Block first last>
            <Flex>
              <h1 className="h0">Schedules</h1>

              <FlexSpacer />

              <div
                className="margin-left-very-big padding-top-big full-width"
                style={{ maxWidth: 420 }}
              >
                <Input
                  type="search"
                  value={term}
                  onChange={e => setTerm(e.target.value)}
                  placeholder="Search"
                  className="middle full-width"
                />
              </div>
            </Flex>

            {featuredSchedules.length <= 0 ? null : (
              <>
                <h4 className="subtitle">
                  Featured Schedule
                  {featuredSchedules.length === 1 ? `` : `s`}
                  {term ? (
                    <>
                      {' '}
                      Matching <q>{term}</q>
                    </>
                  ) : null}
                </h4>

                <hr />

                <Yoga maxCol={2} className="margin-bottom-big">
                  {featuredSchedules.map((node, i) => (
                    <ScheduleCard
                      key={i}
                      slug={node?.fields?.slug!}
                      title={node?.frontmatter?.title!}
                      happensOn={node?.frontmatter?.happensOn!}
                      location={node?.frontmatter?.location!}
                      excerpt={node?.excerpt}
                    />
                  ))}
                </Yoga>
              </>
            )}

            <h4 className="subtitle">
              {featuredSchedules.length > 0 ? `Other` : `All`} Schedule
              {otherSchedules.length === 1 ? `` : `s`}
              {term ? (
                <>
                  {' '}
                  Matching <q>{term}</q>
                </>
              ) : null}
            </h4>

            <hr />

            {otherSchedules.length <= 0 ? (
              <div className="margin-vertical-big">
                No {featuredSchedules.length > 0 ? `more ` : ``}
                schedules found.
              </div>
            ) : (
              <Yoga maxCol={3}>
                {otherSchedules.map((node, i) => (
                  <ScheduleCard
                    key={i}
                    slug={node?.fields?.slug!}
                    title={node?.frontmatter?.title!}
                    happensOn={node?.frontmatter?.happensOn!}
                    location={node?.frontmatter?.location!}
                    excerpt={node?.excerpt}
                  />
                ))}
              </Yoga>
            )}
          </Block>
        </Content>

        <EmailRegistration />
      </Layout>
    </>
  )
}

export default Schedules

export const query = graphql`
  query SchedulesPage {
    featuredSchedules: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "schedule" }, isFeatured: { eq: true } }
      }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            happensOn
            location
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }

    otherSchedules: allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "schedule" }, isFeatured: { ne: true } }
      }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            happensOn
            location
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`

import React from 'react'
import { PageRendererProps } from 'gatsby'

import App from '../app'
import Schedules from '../../layout-default/pages/schedules/schedules'

export default (props: PageRendererProps) => (
  <App pageRendererProps={props}>
    <Schedules />
  </App>
)
